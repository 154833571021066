<script setup lang="ts">
const { isDesktop, isIos } = useDevice();
const title = useState("pageTitle", () => "FLIK");
const keywords = useState("pageKeywords", () => "flik, checkout, nuxt");
const route = useRoute();

console.log("route:", route);
const rName: any = route.name || "";
console.log("rName:", rName);
if (hasLength(rName)) title.value = rName.toUpperCase();
</script>
<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta name="description" :content="title" />
    <Meta name="keywords" :content="keywords" />
    <Meta
      v-if="isIos"
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
  </Head>
  <LazyHeader class="max-w-full p-[16px]" />

  <section
    id="oneColFooter"
    class="border border-transparent relative h-full max-w-full scrollbar-hide md:pb-10"
  >
    <div
      class="relative z-1 h-full"
      :class="[isDesktop ? 'xl:container mx-auto' : '']"
    >
      <NuxtNotifications
        v-if="route.name !== 'cart'"
        classes="notify"
        :style="
          useDevice().isDesktop
            ? `position: absolute; z-index:10; top: 0; left: 0px;`
            : `position: absolute; z-index:10; top: -15px; left: 0px;`
        "
        width="auto"
        :duration="-5000"
        :pause-on-hover="true"
        :max="1"
      >
        <template #body="props">
          <div class="notify" :class="props.item.type">
            <div v-html="props.item.text" />
            <button class="close" @click="props.close">
              <LazyIconsClose class="h-4" />
            </button>
          </div>
        </template>
      </NuxtNotifications>
      <slot />
    </div>
  </section>
  <LazyFooter
    class="mb-[90px] sm:mb-[160px] md:mb-0 w-full xs:relative xs:z-0 md:fixed md:z-2 md:bottom-0"
  />
</template>
